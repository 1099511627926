<template>
  <div
    class="tooltip-container"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <slot />
    <!-- The tooltip element shows if isTooltipVisible is true -->
    <div
      v-if="isTooltipVisible"
      class="tooltip"
      v-html="content"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    ></div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { debounce } from 'lodash';

defineProps({
  content: {
    type: String,
    required: true,
  },
});

// Visibility state for the tooltip
const isTooltipVisible = ref(false);

// Debounced function to hide the tooltip after 100ms
const hideTooltip = debounce(() => {
  isTooltipVisible.value = false;
}, 100);

// When hovering anywhere over the component or tooltip,
// cancel the hide timer and show the tooltip.
const handleMouseEnter = () => {
  hideTooltip.cancel();
  isTooltipVisible.value = true;
};

// When leaving the component or tooltip,
// trigger the debounced hide function.
const handleMouseLeave = () => {
  hideTooltip();
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip {
  position: absolute;
  bottom: 115%;
  left: 50%;
  transform: translateX(-70%);
  background-color: var(--system-white);
  color: var(--system-black);
  text-align: center;
  padding: var(--sizing-1) var(--sizing-2);
  border: 1px solid var(--gray-2);
  border-radius: var(--radius-small);
  transition: opacity 0.3s;
  white-space: nowrap;
  font-size: var(--font-xsmall);
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 70%;
  transform: translateX(-50%);
  border-top: 5px solid var(--gray-2);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
</style>
